import React from 'react';
import { Container } from 'react-bootstrap';
import { Icon } from 'semantic-ui-react';
import MyNavbar from '../components/MyNavbar';

function RadGradOverview(props) {

    function backPage(e) {
        e.preventDefault();
        props.history.push('/projects/radgrad');
    }

    return (
        <div className='walkthrough'>

            <MyNavbar />
            
            <Container className="resume">
                <Icon name='arrow left' size='big' onClick={backPage}/>
                <h1 className='project-header'>Why RadGrad?</h1>
                <hr/>

                <h1 className='project-subheader'><u>The Purpose of RadGrad</u></h1>
                <p>
                    The overarching goals of RadGrad is to increase <b>engagement</b>, <b>retention</b>, and <b>diversity</b> in the field of 
                    computer science. These goals are achieved through various features, tools, and theories implemented into RadGrad, such as 
                    Individual Learning Plans (ILP) and Communities of Practice (CoP). RadGrad also provides an alternative for evaluating 
                    student success with a metric called ICE. ICE is an acronym for Innovation, Competency, and Experience, which takes into account
                    not only how a student performs in courses, but also extracurricular activites they participate in. This helps to address the goal 
                    of retention as students now have another metric they can look at to evaluate their performance in the field, as some students may 
                    not perform well on exams in courses which leads to a lower GPA, but show the drive, passion, and competency in other ways 
                    through extracurricular activites such as internships or Hackathons. One way engagement is addressed is through gameification 
                    by giving users the ability to level up and earn prizes. Diversity is something that an application can't solve itself, however 
                    RadGrad still looks to achieve this goal by building the Communities of Practice through displaying to users others who have added 
                    the same interests, courses, career goals, and opportunities as them.
                </p>

                <h1 className='project-subheader'><u>Improvements To RadGrad</u></h1>
                <p>
                    Although RadGrad has already done a lot to work towards the goals that were set, there is still more that can be done. Imposter syndrome 
                    is prevalent in the field of computer science which leads to a lot of apprehension towards continuing in the field or engaging in areas 
                    of the field that one might not be familiar with. Adding a "level" (i.e. Beginner, Intermediate, Expert, Any, etc..) tag to opportunities can help 
                    students feel more confident and willing to join different opportunities knowing what level of expertise is expected when going in. Another feature 
                    that should be considered is a Questions and Answers or the ability to allow other students to reply to reviews. This can increase engagement by allowing 
                    students to interact with one another and also receive more tailored information.
                </p>

                <h1 className='project-subheader'><u>Pilot Study</u></h1>
                <p>
                    Various pilot studies targeting different demographics should be done over this summer to gain a better understanding of what works and 
                    what doesn't in the current implementation of RadGrad. The first study should target recent or upcoming graduates and probe for 
                    information such as:
                    <ul>
                        <li>What advice would you give new computer science students?</li>
                        <li>What websites/tools/applications/etc did you find most useful throughout college?</li>
                        <li>Do you think that you would have used RadGrad had it been fully developed?</li>
                    </ul>
                    The purpose of these questions are to gain a better understanding from students who have already had the experience of going through college
                    and get an idea of what they felt was most important to their education and success.
                </p>

                <p>
                    The next set of pilot studies can target high school students and incoming students. These studies should be done with the focus of 
                    gaining an understanding of the questions and concerns incoming students have. By finding ways to address these issues, it can help 
                    with engagement and retention since students can have the RadGrad resource to fall back on. Some questions might be:
                    <ul>
                        <li>Why (are you considering/did you choose) to pursue a degree in computer science?</li>
                        <li>Was a computer science class an option at your school?</li>
                        <li>General questions?</li>
                    </ul>
                </p>

                <p>
                    The last set of pilot studies can target recruiters/hiring managers in the field. This target demographic most likely won't be users 
                    of the application, however they can provide valuable information that helps to improve the system. These studies will be used to 
                    design features in RadGrad that help student become more appealing to recruiters by the time they graduate. Some questions might be:
                    <ul>
                        <li>Do you value an applicants GPA or extracurricular work more?</li>
                        <li>What makes some applicants stand out more than others?</li>
                        <li>What skills are you looking for in an applicant?</li>
                    </ul>
                    By presenting this type of information to students it can help those who feel lost by providing a little more structure. This information 
                    however, should not discourage students from taking courses or doing opportunities that they enjoy, even if they may not appeal to recruiters.
                </p>
                
            </Container>

        </div>
    );
}

export default RadGradOverview;

