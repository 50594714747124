import React from 'react';
import { Container } from 'react-bootstrap';
import { Icon } from 'semantic-ui-react';
import MyNavbar from '../components/MyNavbar';
import CarouselImages from '../components/CarouselImages';
import Landing from '../../static/images/shockingemissionslanding.PNG';
import AddEmissions from '../../static/images/addemissions.PNG';
import Home from '../../static/images/shockinghome.png';
import Profile from '../../static/images/shockingprofile.png';
import Calculator from '../../static/images/shockingcalculator.png';
import Data from '../../static/images/shockingdata.png';

function ShockingEmissions(props) {

    const imgArr = [Home, Profile, Calculator, Data];

    function backPage(e) {
        e.preventDefault();
        props.history.push('/projects');
    }

    return (
        <div className='walkthrough'>

            <MyNavbar />
            
            <Container className="resume">
                <Icon name='arrow left' size='big' onClick={backPage}/>
                <h1 className='project-header'>Shocking Emissions</h1>
                <hr/>

                <h1 className='project-subheader'><u>Overview</u></h1>
                <p>
                    "Shocking Emissions" is a web application built using the <a target="_blank" rel="noopener noreferrer" href="https://www.meteor.com/">Meteor Framework</a> with
                    React for the front-end and MongoDB as the database. The website was developed over a span of about 5 months for ICS 414, and was commissioned 
                    by <a target="_blank" rel="noopener noreferrer" href="https://www.hei.com/home/default.aspx">Hawaii Electric Industries.</a> The
                    class was split into 4 teams, with each team consisting of about 7-8 members. I was a part of the team "Shocking Development" 
                    which the project group page for can be found <a target="_blank" rel="noopener noreferrer" href="https://shocking-development.github.io/">here.</a> The 
                    website aims to encourage users to track and reduce their carbon emissions produced through different modes of transportation. This is done by
                    displaying to users how much carbon they produced from driving, or showing users how much they saved by choosing to take an alternative mode of transportation.
                </p>
                <img src={Landing} className='walkthrough-img' alt="not loading" />
            
                <h1 className='project-subheader'><u>Contribution</u></h1>
                <p>
                    Since almost the entire semester was dedicated towards the development of this website, the schedule of the class was broken up into 6 milestones with about 2-3
                    weeks inbetween each milestone. Since we followed an issue driven agile project management workflow, these milestones served as a great checkpoint to get feedback on our
                    design and progress.
                </p>
                <p>
                    <b>Milestone 1:</b> With this being the first milestone of the semester a lot of the time was used for getting to know each other and discussing plans for the project. We
                    focused on creating mockups for different pages of the website, and I created a mockup of the edit profile page along with implementing the mockup in code.
                </p>
                <p>
                    <b>Milestone 2:</b> For this milestone I began working on the Add Emissions page by designing a mockup of the page and implementing it in code. I also worked on
                    implementing the backend for this page by creating a collection for emissions as well a the trips collection which allows users to save previous trips.
                </p>
                <p>
                    <b>Milestone 3:</b> This milestone was back-end focused as I improved upon the emissions and trips collection by adding a schema to them. Before implementing this, any field
                    could be added into the collections. The functionality of deleting trips was also implemented during this milestone.
                </p>
                <p>
                    <b>Milestone 4:</b> Before this milestone only recently added emissions were displayed to the user, so for this milestone I added a table that listed out all of the emissions
                    the user had added. CSS improvements and bug fixes were also addressed during this milestone.
                </p>
                <p>
                    <b>Milestone 5:</b> The list of added emissions implemented in Milestone 4 displayed the entire list of users emissions on one page which is unreasonable when the data
                    set gets too large, so for this milestone I implemented pagination to the list of emissions. I also gave the user the ability to choose their car when selecting "Drove" as
                    their transportation so that we could calculate data accurately based of the cars MPG. Minor bug fixes were also taken care of in this milestone.
                </p>
                <p>
                    <b>Milestone 6:</b> This was the last milestone and at this point the major functions of the website were implemented and a lot of the work revolved around styling the 
                    website and minor bug fixes. The last thing I worked on was adding a dropdown to the Admin Car page which allows the admin to sort the cars by their type; i.e. hybrid, electric, or gas.
                </p>

                <img src={AddEmissions} className='walkthrough-img' alt="not loading" />

                <h1 className='project-subheader'><u>Struggles</u></h1>
                <p>
                    With the project being developed over the course of the entire semester, it is currently the longest period of time I have worked on one project. Because of this, there were 
                    high's and low's during the development and I had to learn how to be consistent. My solution was to set a specific time to work on the project rather than just work on it 
                    whenever I felt like it. An issue that our team as a whole ran into was that a group member wasn't contributing to the project at all. This hindered our development as the 
                    part of the project they were assigned wasn't getting done. After repeated reminders to do their part, we ultimately decided to redistribute their work.
                </p>

                <h1 className='project-subheader'><u>What I Learned</u></h1>
                <p>
                    This project helped sharpen my full stack web development skills using the Meteor framework with React and MongoDB. I also used this as an opportunity to learn how to code 
                    functional React componenents and use React Hooks. Since this project was commissioned by Hawaii Electric Industries, it was the first time I was developing an application for 
                    a customer. It gave me the experience of communicating the progress of the application throughout its development to the customer, as well as listening to what they liked and 
                    didn't like and features they would like implemented.
                </p>

                <h1 className='project-subheader'><u>Link(s)</u></h1>
                <ul>
                    <li className='project-list'>A user guide of the website and developers guide can be found <a target="_blank" rel="noopener noreferrer" href="https://shocking-development.github.io/">here.</a></li> 
                    <li className='project-list'>The github repository can be found <a target="_blank" rel="noopener noreferrer" href="https://github.com/shocking-development/shockingdevelopment">here.</a></li>
                </ul>
                
                <hr/>

                <CarouselImages images={imgArr} />
                
            </Container>

        </div>
    )
}

export default ShockingEmissions;
