import React from 'react';
import { Container } from 'react-bootstrap';
import { Icon } from 'semantic-ui-react';
import MyNavbar from '../components/MyNavbar';
import TheVoteOffWalkthrough from '../components/TheVoteOffWalkthrough';
import LargeImages from '../../static/images/largeimages.PNG';
import CarouselImages from '../components/CarouselImages';
import AdminPage from '../../static/images/adminpage.PNG';
import ResultsPage from '../../static/images/resultspage.PNG';
import ProfilePage from '../../static/images/profilepage.PNG';

function TheVoteOff(props) {

    const imgArr = [AdminPage, ResultsPage, ProfilePage];

    function backPage(e) {
        e.preventDefault();
        props.history.push('/projects');
    }

    return (
        <div className='walkthrough'>

            <MyNavbar />

            <Container className="resume">
                <Icon name='arrow left' size='big' onClick={backPage}/>
                <h1 className='project-header'>The VoteOff</h1>
                <hr/>

                <h1 className='project-subheader'><u>Background</u></h1>
                <p>With the free time I had over the summer of 2020 I decided to learn the coding language python. While I was self learning, my father came to me with a 
                    project idea. He explained how there used to be forums that would run a voting system that allowed users to select 2 choices; the first choice 
                    would be the choice they liked; while the second choice would be the choice they disliked. Essentially, they would add a point to the choice they wanted 
                    while simultaneously removing a point from the choice they disliked the most. Since I was already learning python at the time, I found the django framework 
                    which seemed like a good option to develop this website in as it combined both frontend and backend development easily.</p>
            
                <h1 className='project-subheader'><u>Overview</u></h1>
                <p>As I mentioned before, The VoteOff is built using only the django framework along with postgresql as the database. Users are first greeted with the 
                    landing page where they can login or click the link to register for an account. There are two types of contests; public and private. Anyone can participate in 
                    public contests, while private contests can only be accessed by people who are invited to join it. The vision is that public contests can be used to host large 
                    scale contests where they want interaction from anyone and everyone. Public contests can be viewed by anyone, but to participate in any contests a person must 
                    register for an account. Private contests can be created for smaller contests, possibly by groups of friends who want to vote on what movie to watch or where to eat.</p>
            
                <TheVoteOffWalkthrough />

                <h1 className='project-subheader'><u>UI Improvements</u></h1>
                <p>When developing The VoteOff, getting the functionality of the website to work was put ahead of developing the front-end UI. Because of this, the UI across 
                    the whole website can be improved upon. Images that are uploaded are displayed true to size which leads to varying image sizes on the contest pages and has 
                    led to me disabling images being displayed next to choices. The page where users create a contest can also be improved upon as it is quite clumped together and 
                    might not be clear to users what each form field corresponds to. Another issue is that private contests that users were invited to do not show up under "My Contests" and 
                    can only be accessed through a direct link to the URL of the contest. There is also no way for users to add people to the private contest after leaving the "Add Users" page.</p>
                    <img src={LargeImages} className='walkthrough-img' alt="not loading" />

                <h1 className='project-subheader'><u>Backend Improvements</u></h1>
                <p>Concurrency issues may occur if multiple users are submitting votes to the same contest at the same time. This could lead to choices getting negative votes, or choices 
                    gaining votes after they should have already been eliminated. This could be done by making each vote attempt a transaction which means only one vote attempt interacts 
                    with the database at a time. New tables that show ongoing, upcoming, and completed contests can also be added to help improve the frontend UI by being able to display 
                    contests based off of those parameters.</p>
                
                <h1 className='project-subheader'><u>Future of The VoteOff</u></h1>
                <p>Production on this iteration of The VoteOff will most likely not continue. After working with the django framework and learning more about it, I learned that 
                    although it has the capability of creating a fullstack website by itself, it is mainly used to build backend API's. My knowledge of web development as a whole 
                    has also increased, and for large scale projects like this it is best to use different technologies that excel at certain aspects such as using react for front-end 
                    development and django or node for backend development. If I were to pursue creating a new implementation of The VoteOff, I would develop it in the way previously 
                    mentioned by splitting the project up into frontend and backend. An advantage of doing this as well is that by creating a separate backend API, the frontend can be 
                    developed as a website or as a mobile application. </p>

                 <h1 className='project-subheader'><u>Link(s)</u></h1>
                    <ul>
                        <li className='project-list'>The github repository can be found <a target="_blank" rel="noopener noreferrer" href="https://github.com/trey-sumida/voteoff-django">here.</a></li>
                    </ul>
                    
                <hr/>
                
                <CarouselImages images={imgArr} />

            </Container>

        </div>
    )
}

export default TheVoteOff;