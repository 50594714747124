import React from 'react';
import { Container } from 'react-bootstrap';
import { Icon } from 'semantic-ui-react';
import MyNavbar from '../components/MyNavbar';
import Task1 from '../../static/images/task1.png';
import Task2 from '../../static/images/task2.png';
import Task2Admin from '../../static/images/task2admin.png';
import Task3Refresh from '../../static/images/task3refresh.gif';
import Task3Update from '../../static/images/task3update.gif';
import Task4 from '../../static/images/task4.png';
import Task5Pre from '../../static/images/task5pre.png';
import Task5Post from '../../static/images/task5post.png';
import Task6Pre from '../../static/images/task6pre.png';
import Task6Post from '../../static/images/task6post.png';
import Task6Success from '../../static/images/task6success.png';
import Task6Error from '../../static/images/task6error.png';
import Task6Confirmed from '../../static/images/task6confirmed.png';
import Task7 from '../../static/images/task7.png';
import Task7Modal from '../../static/images/task7modal.gif';

function RadGradSandbox(props) {

    function backPage(e) {
        e.preventDefault();
        props.history.push('/projects/radgrad');
    }

    const task1Wrong = "<RadGradSegment header={</RadGradHeader title='TASK 1: HELLO WORLD' icon='globe americas' dividing/>} children='Hello World'/>";
    const radGradSegment = 'const RadGradSegment: React.FC<RadGradSegmentProps> = ({ header, style = {}, children })';
    const task1Correct = "<RadGradSegment header={<RadGradHeader title='TASK 1: HELLO WORLD' icon='globe americas' dividing/>}>Hello World</RadGradSegment>"
    const useParamsCode = 'const { username } = useParams();'
    
    return (
        <div className='walkthrough'>

            <MyNavbar />
            
            <Container className="resume">
                <Icon name='arrow left' size='big' onClick={backPage}/>
                <h1 className='project-header'>Building a Personal Sandbox</h1>
                <hr/>

                <h1 className='project-subheader'><u>Table of Contents</u></h1>
                <ul style={{ textAlign: 'left' }}>
                    <li><a href='#task1'>Task 1</a></li>
                    <li><a href='#task2'>Task 2</a></li>
                    <li><a href='#task3'>Task 3</a></li>
                    <li><a href='#task4'>Task 4</a></li>
                    <li><a href='#task5'>Task 5</a></li>
                    <li><a href='#task6'>Task 6</a></li>
                    <li><a href='#task7'>Task 7</a></li>
                </ul>

                <h1 className='project-subheader' id='task1'><u>Task 1</u></h1>
                <p>
                   Task 1 was a great lesson for me as it was a reminder that not everything is as easy as it seems. Although I was able to complete the task with 
                   relative ease, I didn't accomplish it in the best way possible. For example my original solution was:
                </p>
                   <code>{task1Wrong}</code>
                    <br/>
                    <br/>
                <p>
                    This piece of code renders everything correctly so I had assumed that I completed the task, however it fails the eslint 
                    test and doesn't follow best coding practices. The RadGradSegment component is defined as such:
                </p>
                    <code>{radGradSegment}</code>
                    <br/>
                    <br/>
                <p>
                    This led to me to think that the children property was passed explicitly like how header propert was passed into the component. The 
                    issue with this is that the children property should not be passed as a prop into the component, and instead nested between the opening 
                    and closing tags of the component. The correct solution that follows best coding practices looks like:
                </p>
                    <code>{task1Correct}</code>
                    <br/>
                    <br/>
                <img src={Task1} className='walkthrough-img' alt="not loading" />

                <h1 className='project-subheader' id='task2'><u>Task 2</u></h1>
                <p>
                    The focus of task 2 was to start learning different methods of accessing "state" (i.e. data) throughout the application at a more 
                    "global" level. The task involved getting the currently logged in user, as well as grabbing a parameter that was in the form of 
                    a URL path variable. There were no real issues completing this task as I have worked with Meteor before so it was easy to get the 
                    currently logged in user. I did however learn an alternative way of getting a path variable. In other projects where a path variable was 
                    passed, I used the following code:
                </p>
                    <code>props.match.params.contestId</code>
                    <br/>
                    <br/>
                <p>
                    To complete this task, I was made aware of the useParams() hook from the react-router-dom package, which makes the code easier to read and 
                    understand as deconstruction can be used to grab the specific path variables that you want. This is extremely useful especially when
                    there are multiple path variables that need to be accessed.
                </p>
                    <code>{useParamsCode}</code>
                    <br/>
                    <br/>
                <img src={Task2} className='walkthrough-img' alt="not loading" />
                <p style={{ textAlign: 'center '}}>The example above shows the information that is displayed when the logged in user is the same as the username in the URL.</p>
                <img src={Task2Admin} className='walkthrough-img' alt="not loading" />
                <p style={{ textAlign: 'center '}}>The example above shows the information that is displayed when teh logged in user differs from the username in the URL.</p>
                
                <h1 className='project-subheader' id='task3'><u>Task 3</u></h1>
                <p>
                    Task 3 focused on getting data from miniMongo and reactively displaying the information. This task was a good refresher on how to 
                    access data from the database and using withTracker. Like in task 2, previous project I have worked on I used a different method for 
                    accessing data from the database. Instead of withTracker I used the useTracker hook instead, and so for this task I had to learn how 
                    withTracker is used and implemented. The CareerGoalBrowserViewPage.tsx helped me immensely to complete this task. In the GIF below we 
                    can see that when a new career goal is added to the database, the component automatically refreshes to reflect the new data.
                </p>
                <img src={Task3Update} className='walkthrough-img' alt="not loading" />
                <p>
                    The second subtask was to load a random career goal from the database on refresh. I had a general idea of how I wanted to accomplish this 
                    however I didn't quite know how to specifically implement it. My thought process was that since we are given an array of 
                    career goals from the withTracker, all I had to do was access a random index from that array. I knew of the Math.random() function 
                    in Javascript, but didn't know how to generate a random number between certain limits. I 
                    found this article <a target="_blank" rel="noreferrer" href="https://attacomsian.com/blog/javascript-get-random-value-from-array">https://attacomsian.com/blog/javascript-get-random-value-from-array</a> which 
                    helped me implement the restraints. Below we can see that when the page is refreshed, a new career goal is displayed.
                </p>
                <img src={Task3Refresh} className='walkthrough-img' alt="not loading" />

                <h1 className='project-subheader' id='task4'><u>Task 4</u></h1>
                <p>
                    Task 4 incorporated more custom built components that are commonly used throughout the application, as well working with multiple 
                    collections on one page and rendering the information. This task did not give me much trouble as task 3 brought me up to speed 
                    with how to access data from mini-mongo. Once I had arrays of each collection, I have work with the .map() method before so iterating 
                    over each array and displaying the labels was easy. The one thing I am slightly confused about is what the findNotRetired() method 
                    does and different ways it can be implemented.
                </p>
                <img src={Task4} className='walkthrough-img' alt="not loading" />

                <h1 className='project-subheader' id='task5'><u>Task 5</u></h1>
                <p>
                    Task 5 has been the most challenging task so far as I have not worked with uniforms before so there was a learning curve 
                    understanding how to implement it. I used the AddInterestForm.tsx file as a reference for how to implement uniforms as the 
                    page also had a dropdown select field. Dropdowns are one of the harder form input fields to implement as it must be populated 
                    with pre-defined options for the user to select from and so we must ensure that when an option is selected the value 
                    corresponding with that option is correct.
                </p>
                <img src={Task5Pre} className='walkthrough-img' alt="not loading" />
                <p>
                    The second part of the task was to display the description for the selected interest when the user clicks 
                    the "Display description" button. This part of the task didn't give me much of a struggle as I have worked with rendering 
                    content dynamically with react using ternary operator.
                </p>
                <img src={Task5Post} className='walkthrough-img' alt="not loading" />

                <h1 className='project-subheader' id='task6'><u>Task 6</u></h1>
                <p>
                    Task 6 built off of task 5 by now practicing writing to the database rather than just retrieving data to be displayed. Overall this 
                    task didn't give me too much trouble and only minor issues came up. I first modified the code from task 5 to suit the needs of task 6 both 
                    stylistically and functionality wise. Instead of storing just the description of the selected interest in the state, I elected to store 
                    the entire interest object. I then pass the selected interest as a prop into a separate component that I created called Task6EditDescription.tsx which 
                    handles displaying the form for updating the interest with the new description.
                </p>
                <p>
                    The issue I ran into when working on this task was that everything seemed to have worked fine, meaning that after a user selected an interest 
                    the form field would be displayed, and after submitting the new description the success message would display, however when I went to view the 
                    new description, the old one was still being displayed. After analyzing the updateMethod.callPromise() function, I realized that I wasn't correctly 
                    passing the id of the interest I was trying to update. Initially, I was setting updateData equal to the interest object that was passed down and 
                    updating the description field to the new one. In the updateMethod.callPromise() function, the updateData referes to a field named "id" when calling 
                    the update function for the specific collection, however the updateData only has a field named "_id" that it inherits from being initialized 
                    to the interest object. This didn't throw an error, however it did mean that no document was being referenced thus leading to no actual change in 
                    the database. To fix this, instead of initializing the updateData variable to the interest object, I instead initialze it to the doc object which 
                    stores the new description. I then add an "id" field to the object which stores the docid of the interest that is selected. Now when the updateMethod.callPromise() 
                    function references updateData.id, the field is not null.
                </p>
                <img src={Task6Pre} className='walkthrough-img' alt="not loading" />
                <p style={{ textAlign: 'center '}}>
                    The image above shows the initial state of task 6.
                </p>
                <img src={Task6Post} className='walkthrough-img' alt="not loading" />
                <p style={{ textAlign: 'center '}}>
                    The image above shows the state of task 6 after a user selects an interest.
                </p>
                <img src={Task6Success} className='walkthrough-img' alt="not loading" />
                <p style={{ textAlign: 'center '}}>
                    The image above shows the success message after the user successfully updates the description of an interest.
                </p>
                <img src={Task6Confirmed} className='walkthrough-img' alt="not loading" />
                <p style={{ textAlign: 'center '}}>
                    The image above shows confirmation that the description was indeed updated.
                </p>
                <img src={Task6Error} className='walkthrough-img' alt="not loading" />
                <p style={{ textAlign: 'center '}}>
                    The image above shows the error message that occurs if a non-authorized users tries to update an interest.
                </p>

                <h1 className='project-subheader' id='task7'><u>Task 7</u></h1>
                <p>
                    Task 7 was an open-ended task that only required the implementation of a tabbed and modal component. These two components 
                    are commonly used throughout the website and so understanding how to use them is crucial. Since the only requirement was that 
                    the component uses tabs and a modal, we were left to our own devices when it came to what the tabs and modal displays. I 
                    decided to take inspiration from task 4 and divide the information that was displayed in that component into their own 
                    tabs. In the screenshot below we can see there are 6 tabs: Career Goals; Courses; Interests; Opportunities; Students; and 
                    Task 2. The first 5 tabs each display their corresponding list of labels while Task 2 displays a button.
                </p>
                <img src={Task7} className='walkthrough-img' alt="not loading" />
                <p style={{ textAlign: 'center '}}>
                    In the GIF below we can see that a modal containing the task 2 component is displayed when the "Who is the user?" button is pressed.
                </p>
                <img src={Task7Modal} className='walkthrough-img' alt="not loading" />
                
            </Container>

        </div>
    );
}

export default RadGradSandbox;

