import React from 'react';
import { Container } from 'react-bootstrap';
import MyNavbar from '../components/MyNavbar';
import Headshot from '../../static/images/Headshot.jpg';

function AboutMe() {

    document.title = 'Trey Sumida | About Me';

    return (
        <div className='resume-background'>
            <MyNavbar />

            <Container className="aboutme-background">
                <h1 className='contact-header'>About Me</h1>
                <img src={Headshot} className='aboutme-image' alt="profile pic" />

                <p>
                    Hello, my name is Trey Sumida and I am a recent graduate from the University of Hawaii at Manoa where I graduated with a Bachelor's of Science degree in Computer Science.
                </p>

                <p>
                    I have always had a passion for building and technology has always been a huge part of my life. Ever since a young age,
                    whether it was playing with legos to helping my grandpa build greenhouses, I have always been a hands on person. My father's
                    interest in technology led to mine as when he would get something new, I would be the beneficiary receiving the old device.
                    I see software engineering, web development especially, as a great mixture of both.
                </p>

                <p>
                    On my free time I enjoy playing video games and watching or playing basketball. Video games allow me to hang out with
                    friends that I might not be able to physically hang out with. There is a lot of sitting infront of a screen involved in
                    computer science and basketball keeeps me active by making exercise fun. At the end of the day I throw a game on the TV
                    and just relax.
                </p>
            </Container>
        </div>
    )
}

export default AboutMe;
