import React from 'react';
import { Container } from 'react-bootstrap';
import { Icon } from 'semantic-ui-react';
import MyNavbar from '../components/MyNavbar';
import CarouselImages from '../components/CarouselImages';
import Landing from '../../static/images/radgradlanding.png';
import Home from '../../static/images/radgradhome.png';
import Terms from '../../static/images/termsconditions.png';
import Interests from '../../static/images/radgradinterests.png';
import Visibility from '../../static/images/visibility.png';
import Reviews from '../../static/images/reviews.png';
import Opportunity from '../../static/images/opportunity.png';
import Levels from '../../static/images/levels.png';
import ICE from '../../static/images/ice.png';
import DegreePlanner from '../../static/images/degreeplanner.png';
import Community from '../../static/images/community.png';

function RadGradReview(props) {

    const imgArr = [Landing, Home, Terms, Interests, Visibility, Reviews, Opportunity, Levels, ICE, DegreePlanner, Community];

    function backPage(e) {
        e.preventDefault();
        props.history.push('/projects/radgrad');
    }

    return (
        <div className='walkthrough'>

            <MyNavbar />
            
            <Container className="resume">
                <Icon name='arrow left' size='big' onClick={backPage}/>
                <h1 className='project-header'>A Critical Review of RadGrad</h1>
                <hr/>

                <h1 className='project-subheader'><u>Landing Page</u></h1>
                <img src={Landing} className='walkthrough-img' alt="not loading" />
                <p>
                    I like the overall feel and layout of the landing page with it being broken up into different sections as well as giving users a general overview of why they should use RadGrad. There are quite a few of minor css errors documented below:
                  <ul>
                    <li>“Choosen” should be “Chosen” as well as a space after the comma in the sentence “define,then”</li>
                    <li>The middle section that displays the statistics of the website is not centered vertically as there is more space at the bottom compared to the top. Unsure if this can really be resolved as the last column has 2 rows of text that is most likely causing this</li>
                    <li>I feel like there are too many colors/similar colors being used throughout the page. Looking at just the last section, the title green, the links in the paragraph, and the images, all use different shades of green. Having at least all the “body” elements (i.e. the links, images, and cards) the same color would make it feel more uniform</li>
                  </ul>
                </p>

                <h1 className='project-subheader'><u>Home Page</u></h1>
                <img src={Home} className='walkthrough-img' alt="not loading" />
                <p>
                The To Do list being broken up into the varying priorities make it very easy and intuitive to follow what needs to be done. The 
                level that the badge represents should be displayed on hover of the badge. With there being 6 levels, it may be hard for users to 
                remember what level the badge color corresponds to and they have to navigate to the specific “Levels” page to know.
                </p>


                <h1 className='project-subheader'><u>Terms and Conditions</u></h1>
                <img src={Terms} className='walkthrough-img' alt="not loading" />
                <p>
                Giving the user the ability to Accept/Reject the terms and conditions on this page should be considered so that they don’t 
                have to navigate back to the To Do list in order to do so. Since it is only text being displayed, placing it inside of a container 
                to give padding on the sides will make it easier to read. There is also no padding on the bottom of the page below the last line of text.
                </p>

                <h1 className='project-subheader'><u>Interests/Careers/Courses/Opportunities</u></h1>
                <img src={Interests} className='walkthrough-img' alt="not loading" />
                <p>
                The “See Details / Add to Profile” button may want to be split into two separate buttons especially for the pages like 
                Interests/Careers where users won’t always need more details before wanting to add it to their profile. Adding a search 
                functionality to these pages should also be considered so users who know what they want to add don’t have to scroll to find it. The 
                opportunities page should also feature a sort by most recent option so that users can find the most recent and up to date opportunities available.
                </p>

                <h1 className='project-subheader'><u>Individual Interests/Careers/Courses/Opportunities</u></h1>
                <img src={Opportunity} className='walkthrough-img' alt="not loading" />
                <p>
                I like the flow of the layout of these individual pages as the left column displays related information that can give users a quick 
                interpretation of whether or not the interest/career/course/opportunity is something they might be interested in depending on how many 
                related items are highlighted green. There is a bug that displays the same classes in both the “Completed” and “In Plan” sections of Related 
                Courses as well as a minor css issue where not all of the sections in the right column are the same width.
                </p>

                <h1 className='project-subheader'><u>Degree Planner</u></h1>
                <img src={DegreePlanner} className='walkthrough-img' alt="not loading" />
                <p>
                Some courses are shown as have been taken in two different semesters and also still show up in the course tab section. Selecting courses 
                that have already been dragged into the degree planner are hard to click and sometimes select the item below the one that was intended to be 
                clicked on. There is also no padding on the top and bottom of the cards in the right column, making everything feel really cluttered and tight.
                </p>

                <h1 className='project-subheader'><u>Visibility</u></h1>
                <img src={Visibility} className='walkthrough-img' alt="not loading" />
                <p>
                Users being given the ability to decide what information they want to share is a great feature. The user still shows up under the specific 
                interest/career/course/opportunity however so the “Visibility” only affects what is displayed when a user clicks on your label. A few things 
                that caught my attention was first when the website option is unchecked and a user adds a website, there is no confirmation that the addition of the 
                website was successful and is only confirmed when the user checks the website option. The ICE scores are also not uniform and vary in width/height. The 
                labels in the “Interests” tab also have no padding on the top and hug the top of the section.
                </p>

                <h1 className='project-subheader'><u>ICE</u></h1>
                <img src={ICE} className='walkthrough-img' alt="not loading" />
                <p>
                I like how the Innovation and Experience tabs suggest to the user recommended opportunities catered towards them based on their interests. One issue 
                with this page is that hovering over your score indicates that it is a link, however when clicked it leads to a page not found. 
                </p>

                <h1 className='project-subheader'><u>Levels</u></h1>
                <img src={Levels} className='walkthrough-img' alt="not loading" />
                <p>
                The warning that is displayed which notifies the user that they haven’t leveled up in awhile which helps encourage them to work towards that. I do find 
                the “Want to level up?” section to be a little confusing as to what actually has to be done to level up. 
                </p>

                <h1 className='project-subheader'><u>Reviews</u></h1>
                <img src={Reviews} className='walkthrough-img' alt="not loading" />
                <p>
                Letting students add reviews to courses, but especially opportunities can be extremely useful to students who may be intimidated about certain 
                courses/opportunities. Having the course number in addition to the course name is a small but could be significant improvement to the form. Personally, I 
                remember course numbers that I have taken more so than the course name itself, and so without the course number I have to leave the page to find and ensure that 
                I am writing the review for the right course.
                </p>

                <h1 className='project-subheader'><u>Community</u></h1>
                <img src={Community} className='walkthrough-img' alt="not loading" />
                <p>
                The community page is another great feature of RadGrad as it allows users to view cumulative data from the website. Showing how many students are at a certain level 
                helps motivate users to want to level up and be in an elite few. Also by showing the most popular careers/interests/opportunities it can show users what they may be 
                missing out on. 
                </p>

                <h1 className='project-subheader'><u>Recommendations</u></h1>
                <p>
                    The biggest issue I see with RadGrad is that many students may see it as an unnecessary extra work they have to do. Finding ways to emphasize why students should 
                    keep up to date with RadGrad should be implemented throughout the website. Promoting the ICE scoring system as an enhancement to evaluating students can appeal 
                    to students who may not necessarily perform well in courses, but show the drive and competency through other means such as internships or clubs.
                </p>
                
                <hr/>

                <CarouselImages images={imgArr} />
                
            </Container>

        </div>
    );
}

export default RadGradReview;
