import React from 'react';
import MyNavbar from '../components/MyNavbar';
import { Container } from 'react-bootstrap';
import { Icon } from 'semantic-ui-react';
import ResumeFile from '../../static/files/resume.pdf';

function Resume() {

    document.title = 'Trey Sumida | Resume';

    return (
        <div className='resume-background'>
            <MyNavbar />
            <Container className='resume'>
                <embed src={ResumeFile} className='resume-file' type='application/pdf'/>

                <div className="resume-content">
                    <h2><b>Links</b></h2>
                    <hr className="resume-divider" />

                    <ul>
                        <li className='paragraph-text'><a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/trey-allen-sumida/"><Icon name='linkedin'/>Linkedin</a></li>
                        <li className='paragraph-text'><a target="_blank" rel="noopener noreferrer" href="https://github.com/trey-sumida"><Icon name='github'/>Github</a></li>
                    </ul>
                </div>
            </Container>
        </div>
    )
}

export default Resume;
