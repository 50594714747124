import React from 'react';
import Fade from 'react-reveal/Fade';
import ProjectsHeader from '../components/ProjectsHeader';
import ProjectsList from '../components/ProjectsList';
import Zoombg from '../../static/images/zoombg.png';
import GemsLogo from '../../static/images/GEMS-Logo.png';
import VoteOffImg from '../../static/images/voteoffhp.PNG';
import ShockingEmissionsImg from '../../static/images/shockingemissionslanding.PNG';
import MyNavbar from '../components/MyNavbar';
import Container from'react-bootstrap/Container';
import Logo from '../../static/images/SURE-background.png';
import TDLogo from '../../static/images/tdlogo.jpeg';

function Projects() {

    document.title = 'Trey Sumida | Projects';

    const clioDescription = 'Project CLIO is a web application that was built for the Hawaii Annual Code Challenge 2020 hackathon. The challenge we chose to pursue was the gamification of crowdsource indexing for the Hawaii state archives. This project sought to engage the general public in a fun and exciting way to help with the transcription and indexing of state archive records.';
    const theVoteOffDescription = 'TheVoteOff is a personal project that was developed using the django framework along with postgresql as the database. Vote Off provides a different way to vote, allowing users to vote up their favorite choice as well as vote down their least favorite.';
    const gemsDescription = 'For the final project of ICS 314, our group continued our project that we started at HACC 2019. The project was to create a new loan application site for the GEM$ Program. The application was created using the react framework along with mongodb as the database.';
    const shockingEmissionsDescription = 'Shocking Emissions is a web application that was a semester long project for ICS 414 and was commissioned by HEI. The purpose of the website is to have users track their green house gas emissions and encourage users to reduce their carbon footprint.';
    const RadGradInternshipDescription = 'A page documenting my experience as a NSF-REU summer intern working on the RadGrad project. Links to essays written during the internship can be found on this page as well as a general overview of my experience.';
    const twitchDictionaryDescription = 'Twitch Dictionary is a full stack web application built using MongoDB for the database, NodeJS and Express to create the REST API, and NextJS for the front-end. The idea behind Twitch Dictionary is to be a community sourced dictionary that defines various emotes, phrases, and slang used on the website Twitch.TV.'

    return (
        <div className='projects'>
            <MyNavbar />
            <ProjectsHeader />
            <Container className='projects-div'>
                <Fade top><ProjectsList path='/projects/radgrad' title='RadGrad Summer Internship' image={Logo} description={RadGradInternshipDescription} /></Fade>
                <hr/>
                <Fade top><ProjectsList path='/projects/twitchdictionary' title='Twitch Dictionary' image={TDLogo} description={twitchDictionaryDescription} /></Fade>
                <hr/>
                <Fade top><ProjectsList path='/projects/shockingemissions' title='Shocking Emissions' image={ShockingEmissionsImg} description={shockingEmissionsDescription} /></Fade>
                <hr/>
                <Fade top><ProjectsList path='/projects/projectclio' title='Project CLIO' image={Zoombg} description={clioDescription} /></Fade>
                <hr/>
                <Fade top><ProjectsList path='/projects/thevoteoff' title='The VoteOff' image={VoteOffImg} description={theVoteOffDescription} /></Fade>
                <hr/>
                <Fade top><ProjectsList path='/projects/gemsloanportal' title='GEMS LOAN PORTAL' image={GemsLogo} description={gemsDescription} /></Fade>
            </Container>
        </div>
    )
}

export default Projects;
