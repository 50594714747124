import React from 'react';
import { Row, Col } from 'react-bootstrap';

function ProjectsList(props) {
    return (
            <Row className='proj-row'>
                <Col sm={4}><img src={props.image} alt='' className='proj-img'/></Col>
                <Col sm={8} className='proj-col'>
                    <h5 className='proj-links' id='proj-links-header'><u>{props.title}</u></h5>
                    <p className='proj-desc'>{props.description}</p>
                    <a href={props.path} className='proj-links'>Learn More</a>
                </Col>
            </Row>
    )
}

export default ProjectsList;
