import { useHistory } from 'react-router-dom';

function Landing() {

  const router = useHistory();

    return (
      <div>
        <section className='landing-div' id="home">
            <div className='landing-content'>
              <div>
                <p className='landing-header'>Hello, My name is <span className='landing-title'>Trey Sumida</span></p>
                <p className='landing-header'>and I'm a <span className='landing-title'>Software Developer</span></p>
                <div className='landing-nav'>
                  <button className='landing-nav-button' onClick={() => router.push('/aboutme')}>About Me</button>
                  <button className='landing-nav-button' onClick={() => router.push('/projects')}>Projects</button>
                  <button className='landing-nav-button' onClick={() => router.push('/resume')}>Resume</button>
                </div>
              </div>
            </div>
        </section>
      </div>
    )
}

export default Landing;
