import React from 'react';

function ProjectsHeader() {
    return (
        <div className="myprojects">
            <div className="tint">
                <h1 className="proj-header">My Projects</h1>
            </div>
        </div>
    )
}

export default ProjectsHeader;
