import React from 'react';
import Register from '../../static/images/register.PNG';
import Contests from '../../static/images/walkthrough2.PNG';
import PublicContests from '../../static/images/publiccontests.PNG';
import Vote from '../../static/images/vote.gif';
import Limit from '../../static/images/limit.PNG';
import CreateContest from '../../static/images/createcontest.PNG';
import AddUsers from '../../static/images/addusers.PNG';

function TheVoteOffWalkthrough() {
    return (
        <div>
            <h1 className='project-subheader'><u>Basic User Walkthrough</u></h1>
            <p>Users must first register for an account.</p>
            <img src={Register} className='walkthrough-img' alt="not loading" />
            
            <p>After registering, users can now participate in contests by either selecting "Public Contests" in the navigation bar, or private 
                contests by selecting the dropdown and selecting "My Contests."</p>
                <img src={Contests} className='walkthrough-img' alt="not loading" />
            
            <p>Users can click the button "Vote Now" to participate in the contest, or click the button "Results" if they want to view the current state of the contest.</p>
                <img src={PublicContests} className='walkthrough-img' alt="not loading" />

            <p>The voting page displays 2 columns; the first column is where the user chooses which choice they want to add a vote to; the second column is where the 
                user chooses which choice they want to remove a vote from. After submitting their vote, they are redirected to the results page which shows the current 
                vote count for each choice.</p>
                <img src={Vote} className='walkthrough-img' alt="not loading" />
            
            <p>Users are limited to voting once per hour per contests. This is done so that contests cannot be abused by a user or bot submitting votes multiple times 
                when a contests begins. This is also done to increase interaction with the website and contests by urging users to visit the site multiple times 
                and to get friends to join to help your choice win.</p>
                <img src={Limit} className='walkthrough-img' alt="not loading" />
            
            <p>Contests can be created by going selecting the dropdown menu and clicking "Create." The contest title, Description, cover image, start date, end date, type 
                of contest, choices, and the amount of votes the choices start with are all of the fields that must be filled out when creating a contest. Each choice can 
                have an image associated with it as well, however it is not necessary.</p>
                <img src={CreateContest} className='walkthrough-img' alt="not loading" />

            <p>After creating a private contest, the user will be redirected to a page where they can add specific users to be able to participate in the contest.</p>
                <img src={AddUsers} className='walkthrough-img' alt="not loading" />

        </div>
    )
}

export default TheVoteOffWalkthrough;
