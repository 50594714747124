import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

function CarouselImages(props) {

    return (
        <div>
            <Carousel>
            {props.images.map((img, index) => {
                return <Carousel.Item key={index}>
                        <img
                            className="carousel-img"
                            src={img}
                            alt=""
                            />
                    </Carousel.Item>
            })}
            </Carousel>
        </div>
    )
}

export default CarouselImages;
