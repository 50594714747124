import React from 'react';
import Landing from '../../static/images/hac2020landing.PNG';
import TestRecord from '../../static/images/testrecord.PNG';
import Leaderboard from '../../static/images/leaderboard.png';

function ClioWalkthrough() {
    return (
        <div>
            <h1 className='project-subheader'><u>Basic User Walkthrough</u></h1>
            <p>Below is the landing page for the website where users can login or click on the signup link to create an account.</p>
            <img src={Landing} className='walkthrough-img' alt="not loading" />
            
            <p>New users are given the status of "rookie" and must complete a training record in order to be able to access more of the website and start filling out real records.</p>
                <img src={TestRecord} className='walkthrough-img' alt="not loading" />
            
            <p>As users complete records, they are awarded points after an archivist has approved their submission. Users can then check the scoreboard to see if they 
                fall within the top 3 on either the daily, monthly, or all time leaderboards. This is done to incentivize users to complete indexing records and acted as the 
                gamification aspect of the project.</p>
                <img src={Leaderboard} className='walkthrough-img' alt="not loading" />
        </div>
    )
}

export default ClioWalkthrough;
