import React from 'react';
import MyNavbar from '../components/MyNavbar';
import { Container } from 'react-bootstrap';
import { Icon } from 'semantic-ui-react';
import CarouselImages from '../components/CarouselImages';
import Landing from '../../static/images/Landing.png';
import UserLogin from '../../static/images/userlogin.png';
import Application from '../../static/images/application.png';

function GemsLoanPortal(props) {

    const imgArr = [Landing, UserLogin, Application];

    function backPage(e) {
        e.preventDefault();
        props.history.push('/projects');
    }

    return (
        <div className='walkthrough'>

            <MyNavbar />

            <Container className='resume'>
                <Icon name='arrow left' size='big' onClick={backPage}/>
                <h1 className='project-header'>GEMS LOAN PORTAL</h1>
                <hr/>

                <h1 className='project-subheader'><u>Overview</u></h1>
                <p>For the <a href="http://hacc.hawaii.gov/" target="_blank" rel="noopener noreferrer">HACC 2019</a>, <a href="https://wileybui.github.io/" target="_blank" rel="noopener noreferrer">Wiley Bui</a>, <a href="https://github.com/arslan-r" target="_blank" rel="noopener noreferrer">Arslan Rakhmankulov</a>, <a href="https://github.com/ryotabs" target="_blank" rel="noopener noreferrer">Ryota Seki</a>, <a href="https://github.com/brodyu" target="_blank" rel="noopener noreferrer">Brody Uehara</a> and 
                I decided to take on the GEM$ Program challenge which tasked us with developing a new and more user friendly application process for both the applicants and the GEM$ staff. We 
                were introduced to the HACC by our professor for ICS 314 as an extra credit assignment. We chose this challenge as we felt that with our skillset at the time, we could come 
                up with an effective solution for their problems that was within our skillset. Our solution was entitled “Simple Green Loans” and was a website that streamlined the workflow for GEM$ employees, and created an easier to follow and less daunting application form. 
                </p>

                <h1 className='project-subheader'><u>Issues and Our Solution</u></h1>
                    <p>
                        <b>Issue 1:</b> The loan application form was a long pdf file that was hard to read and understand. Applicants were discouraged to fill out the form as the length of the form made 
                        the applicant question if it was worth it. The confusing nature of the form also led to applicants accidentally skipping over input sections thus when they finally
                        send the form, their application is not complete.
                    </p>
                    <p>
                        <b>Solution:</b> Our solution to this problem was to break down the form into different sections so that users can focus on one section at a time, and allows for them to complete
                        a portion of the form and save it, allowing them to come back and finish the form at any time. By doing this, we still are able to present and gather all the information
                        that is needed for the application, however it is displayed in a more eye pleasing and less daunting way by only showing one section of the form at a time. This also
                        makes it easier for users to see if they missed inputting information, and allows us to show errors in the form where the user has not filled in a required section.
                    </p>

                    <p>
                        <b>Issue 2:</b> GEM$ employees would have to manually transfer the information from the application into an excel sheet to keep for their records. This process was time
                        consuming and error prone as employees could accidentally mistype information or enter information into the wrong section.
                    </p>
                    <p>
                        <b>Solution:</b> Our solution to this issue was giving employees the ability to transfer all the forms into an excel sheet at the click of a button. This way we eliminate
                        the human error that could be present when transferring the data by hand. The time saved by doing this also means there is more time for employees to do more important work
                        such as actually processing the applications and deciding which applications can be approved.
                    </p>

                <h1 className='project-subheader'><u>Contribution</u></h1>
                    <p>Our team was divided into front-end and back-end development teams, with some members working on both teams. I worked exclusively on the front-end development of the
                        website which included using reactjs as our front-end framework, along with the semantic-ui library. I designed the login and signup pages along with the application
                        status page for users. The application status page shows users a simple 4 step list which highlights where the application currently is in the application process. On
                        the admin side, I helped create the webpage that allows GEM$ employees to choose users who have not completed their application, and send emails to these users reminding
                        them that they have an unfinished application.
                    </p>

                <h1 className='project-subheader'><u>What I Learned</u></h1>
                    <p>Before this project, I had never really worked with a large group of people. Previous projects I had worked on were done by myself or with a small group of 1-2 other people. Since
                        This experience taught me the importance of project management when working in a large group so that members stay focused and always have something to work on. As mentioned
                        before, we were taking ICS 314 at the time which was the first web development class I had taken. I had no prior experience in web development before this and participating
                        in the HACC helped to sharpen and solidify my skills with web development and more specifically reactjs. 
                    </p>

                <h1 className='project-subheader'><u>Link(s)</u></h1>
                    <ul>
                        <li className='project-list'>The project homepage can be found <a target='_blank' href='https://green-loan-portal.github.io/' rel="noopener noreferrer">here.</a></li>
                    </ul>
                
                <hr/>

                <CarouselImages images={imgArr} />

            </Container>

        </div>
    )
}

export default GemsLoanPortal;
