import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Icon } from 'semantic-ui-react';

function MyNavbar() {
    return (
    <Navbar sticky='top' expand='md' className='mynav'>
        <Nav.Link href='/' className='nav-text'>Trey Sumida</Nav.Link>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
        <Nav.Link href='/aboutme' className='nav-text'>About Me</Nav.Link>
        <Nav.Link href='/projects' className='nav-text'>Projects</Nav.Link>
        <Nav.Link href='/resume' className='nav-text'>Resume</Nav.Link>
        <Nav.Link target="_blank" rel="noopener noreferrer" href="https://github.com/trey-sumida"><Icon name='github' size='large' /></Nav.Link>
        <Nav.Link target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/trey-allen-sumida/"><Icon name='linkedin' size='large'/></Nav.Link>
        </Navbar.Collapse>
    </Navbar>
    )
}

export default MyNavbar;
