import React from 'react';
import MyNavbar from '../components/MyNavbar';
import { Container } from 'react-bootstrap';
import { Icon } from 'semantic-ui-react';
import CarouselImages from '../components/CarouselImages';
import Landing from '../../static/images/tdlanding.png';
import Add from '../../static/images/tdadd.png';
import Created from '../../static/images/tdcreated.png';

function TwitchDictionary(props) {

    const imgArr = [Landing, Add, Created];

    function backPage(e) {
        e.preventDefault();
        props.history.push('/projects');
    }

    return (
        <div className='walkthrough'>

            <MyNavbar />

            <Container className='resume'>
                <Icon name='arrow left' size='big' onClick={backPage}/>
                <h1 className='project-header'>Twitch Dictionary</h1>
                <hr/>

                <h1 className='project-subheader'><u>Overview</u></h1>
                <p>
                  Twitch Dictionary is meant to be a community sourced dictionary that defines various emotes, phrases, and slang used on <a target='_blank' href='https://twitch.tv/' rel="noopener noreferrer">twitch.tv.</a> Twitch is 
                  a livestreaming website/application that is commonly used to livestream video games, but other content can be streamed as well. Emotes are commonly used by viewers in the chat of the stream to express certain emotions 
                  or react to certain things that happen. Emotes can be global throughout the website, meaning that it can be used in every chat, or they can be exclusive to a certain broadcaster in which a person must be subscribed to that 
                  broadcasters channel to be able to use the emote. To a new user, seeing the various emotes in chat may be confusing. Twitch Dictionary looks to cultivate a sense of community by helping these new users to the platform learn 
                  the "language" that is used.
                </p>

                <h1 className='project-subheader'><u>Technologies Used</u></h1>
                <p>
                    <b>MongoDB</b> - I decided to go with MongoDB as the database as long-term it will be easier to scale and the data being stored was not highly relational.
                    <br/>
                    <b>NodeJS</b> - NodeJS was used as the development environment in which the backend API was built on top of.
                    <br/>
                    <b>Express</b> - Express was used to build out the REST API as well as the package mongoose to create and interact with the database.
                    <br/>
                    <b>NextJS</b> - NextJS was chosen as the front-end framework as the various pages could still be built using React, but also provided the opportunity to 
                    render the web pages on the server side. This decision was made as the application heavily relies on SEO optimization for people to find, and so SSR helps 
                    to fix the SEO issue that vanilla React runs into.
                    <br/>
                    <b>OAuth2</b> - I decided to use OAuth2 to allow users to authenticate with their Twitch account as it would provide flexibility in the future to pull data 
                    from their account such as who they follow, and be able to use that information to do something such as suggest definitions based on that information.
                    <br/>
                    <b>Docker</b> - I decided to use docker to containerize the front and back end applications so that there would be no issues with versioning of Node or NPM when 
                    deploying the application.
                    <br/>
                    <b>Vultr.com</b> - I deployed the application to a virtual private server on the Vultr platform using Nginx to handle incoming traffic.
                    <br/>
                    <b>Github</b> - Github was used to store and control versions of the code base.
                    <br/>
                    <b>ESLint/Prettier</b> - ESLint/Prettier was used to standardize the format of the code and fix format issues.
                </p>

                <h1 className='project-subheader'><u>Current Issues and Possible Solutions</u></h1>
                <p>
                  <b>Issue</b> - The functionality for admin to review reports is not intuitive and the website can easily be spam botted with reports.
                  <br/>
                  <b>Solution 1</b> - Require users to be logged in to submit a report. This solution would add that extra layer of authentication and would allow for 
                  tracement of accounts that could potentially be bots with mass reports tied to their account. The drawback to this solution is that this may deter 
                  genuine reports from being filed, meaning that definitions that break guidelines are left on the site.
                  <br/>
                  <b>Solution 2</b> - Add Recaptcha. Implementing recaptcha seems to be the better solution as it will still allow users without accounts to report definitions, while 
                  also adding a layer of protection from bots.
                  <hr/>
                  <b>Issue</b> - Currently, definitions are ranked solely based on the amount of likes they have received. While this is not necessarily a bad thing, this is not the most 
                  optimal solution. Situations could occur where one definition has 300 likes and 300 dislikes, while another has 200 likes and only 10 dislikes, and the first one would be 
                  ranked higher. 
                  <br/>
                  <b>Solution 1</b> - Percentage of likes. This solution would help in the scenario described above as the second definition would have a better percentage, however this 
                  this causes another issue where a definition with 1 like and 0 dislikes (100%) would outrank a definition with 200 likes and 10 dislikes (0.95%).
                  <br/>
                  <b>Solution 2</b> - Ratio of likes to dislikes. This solution works in both scenarios previously mentioned where the "better" definition is ranked higher. There are still cases 
                  in which this solution is not optimal, such as a definition with 10 likes and 1 dislike (10.0 ratio) would rank higher than one with 800 likes and 200 dislikes (4.0 ratio).
                  <br/>
                  <b>Solution 3</b> - Scoring system. If correctly implemented a scoring system would sort of be a "best of both worlds" where both the number of likes and the ratio of the likes to 
                  dislikes would be taken into account. Figuring out how to balance how much a like and dislike is worth is where this solution struggles.
                </p>

                <h1 className='project-subheader'><u>Future Plans</u></h1>
                <p>
                  The current stage of Twitch Dictionary is a minimal viable products (MVP). Searching for definitions is not optimized as well as how they are displayed. I also plan to implement various 
                  features to increase engagement on the website such as: 
                  <ul>
                    <li>Definition of the day</li>
                    <li>Automatically posting the definition of the day to twitter</li>
                    <li>Allowing users to copy a link to the definition or post to other social medias</li>
                  </ul>
                  Another aspiration is to build a chrome extension for so that users don't have to visit the website to look up a definition and can easily do so while they are viewing a 
                  Twitch broadcast. 
                </p>

                <h1 className='project-subheader'><u>Link(s)</u></h1>
                    <ul>
                        <li className='project-list'>The deployed website can be found <a target='_blank' href='https://twitchdictionary.com/' rel="noopener noreferrer">here.</a></li>
                    </ul>
                
                <hr/>

                <CarouselImages images={imgArr} />

            </Container>

        </div>
    )
}

export default TwitchDictionary;
