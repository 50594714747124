import './static/css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Landing from './ui/pages/Landing';
import AboutMe from './ui/pages/AboutMe';
import Projects from './ui/pages/Projects';
import Resume from './ui/pages/Resume';
import ProjectClio from './ui/pages/ProjectClio';
import TheVoteOff from './ui/pages/TheVoteOff';
import GemsLoanPortal from './ui/pages/GemsLoanPortal';
import ShockingEmissions from './ui/pages/ShockingEmissions';
import RadGradReview from './ui/pages/RadGradReview';
import RadGradInternship from './ui/pages/RadGradInternship';
import RadGradOverview from './ui/pages/RadGradOverview';
import RadGradSandbox from './ui/pages/RadGradSandbox';
import TwitchDictionary from './ui/pages/TwitchDictionary';

function App() {
  return (
  <Router>
    <Switch>
      <Route exact path='/' component={Landing} />
      <Route exact path='/aboutme' component={AboutMe} />
      <Route exact path='/projects' component={Projects} />
      <Route exact path='/projects/twitchdictionary' component={TwitchDictionary} />
      <Route exact path='/projects/projectclio' component={ProjectClio} />
      <Route exact path='/projects/thevoteoff' component={TheVoteOff} />
      <Route exact path='/projects/gemsloanportal' component={GemsLoanPortal} />
      <Route exact path='/projects/shockingemissions' component={ShockingEmissions} />
      <Route exact path='/projects/radgrad' component={RadGradInternship} />
      <Route exact path='/projects/radgrad/radgradreview' component={RadGradReview} />
      <Route exact path='/projects/radgrad/radgradoverview' component={RadGradOverview} />
      <Route exact path='/projects/radgrad/radgradsandbox' component={RadGradSandbox} />
      <Route exact path='/resume' component={Resume} />
    </Switch>
  </Router>
  );
}

export default App;
