import React from 'react';
import { Container } from 'react-bootstrap';
import { Icon } from 'semantic-ui-react';
import MyNavbar from '../components/MyNavbar';
import ClioWalkthrough from '../components/ClioWalkthrough';
import CarouselImages from '../components/CarouselImages';
import TeamPic from '../../static/images/teampic.png';
import HACCLogo from '../../static/images/Combined-Logos.png';
import JWT from '../../static/images/jwt.gif';
import Signup from '../../static/images/signup.PNG';
import TrainingLanding from '../../static/images/traininglanding.PNG';

function ProjectClio(props) {

    const imgArr = [Signup, TrainingLanding];

    function backPage(e) {
        e.preventDefault();
        props.history.push('/projects');
    }

    return (
        <div className='walkthrough'>

            <MyNavbar />
            
            <Container className="resume">
                <Icon name='arrow left' size='big' onClick={backPage}/>
                <h1 className='project-header'>Project CLIO</h1>
                <hr/>

                <h1 className='project-subheader'><u>Overview</u></h1>
                <p>The Hawaii Annual Code Challenge, or <a href="https://hacc.hawaii.gov/" target="_blank" rel="noopener noreferrer">HACC</a>, is an annual hackathon run by the 
                State of Hawaii government. The HACC seeks to connect the Hawaii State Government with the tech industry to help strengthen and grow the tech industry in the State 
                of Hawaii. Each year new challenges that have real world application are presented and participants in the HACC are faced with creating a proof-of-concept solution to 
                the challenge. Unlike a traditional hackathon, the HACC spans over multiple weeks and provides workshops that participants can attend to help further their skills and 
                projects development.</p>
                <img src={HACCLogo} className='walkthrough-img' alt="not loading" />
            
                <h1 className='project-subheader'><u>About The Project</u></h1>
                <p>ByteCryb, a team composed of <a target="_blank" rel="noopener noreferrer" href="https://arslan-r.github.io/">Arslan 
                Rakhmankulov</a>, <a target="_blank" rel="noopener noreferrer" href="https://gumsanaung.github.io/">Gum Aung</a>, <a target="_blank" rel="noopener noreferrer" href="https://jhsup.github.io/">Jennifer 
                Hsu</a>, <a target="_blank" rel="noopener noreferrer" href="https://ken-10.github.io/">Ken Tung</a>, and I entered the HACC and chose to take 
                on the challenge <a target="_blank" rel="noopener noreferrer" href="https://hacc.hawaii.gov/wp-content/uploads/2020/10/Challenge_2020_Archives_Gamification.pdf">Gamification 
                of Crowdsource Indexing.</a> This challenge sought to find a fun and exciting way to facilitate the indexing of historic records through the help of the general 
                public. To achieve this goal we created a point system where users would be awarded points after successfully indexing a record. Another issue with the current 
                indexing system used by the state archives is that it is highly error prone. Volunteers would have to switch between the pdf to view the record and the excel sheet 
                to input the data, along with multiple tabs of each. We set out to streamline this process, and achieved our goal by creating a single page that displays both the 
                pdf of the record, and a form with the fields of data which needed to be indexed.</p>
                <img src={TeamPic} className='walkthrough-img' alt="not loading" />

                <p>Our team was split up into two groups: front-end and back-end. Arslan and Jenny worked on the front-end of the website using the react javascript 
                    framework, while Gum, Ken and I worked on the back-end of the website using the spring-boot java framework as well as postgresql as the database. Gum took on 
                    the role of team captain and handled the communication with the organizers of the HACC along with ensuring our team knew and met the deadlines. We team communicated 
                    over Discord and had meetings 4-5 times a week while the hackathon was ongoing. In these meeting we would discuss what needed to be done, and provide progress 
                    updates of what we had finished or are currently working on. We also used github's project board to manage the issues of the project, and created pull requests 
                    so others could double check our work before merging into main.</p>
                <p>My primary task while working on the back-end was to create the signup and login functionality of the website. I decided to use JSON Web Token(JWT) as 
                    the main item for user authentication. The front-end of the website would send an API request with the users login information to the back-end API, and 
                    the API would send back the JWT. From here, any request to the API would need the JWT to be able to access further API endpoints. Google login was also 
                    implemented in the back-end API, however it has not been connected to the front-end. After working on the user authentication, I worked on building various 
                    API end-points such as the badges endpoint and scoreboard endpoint.</p>
                <img src={JWT} className='walkthrough-img' alt="not loading" />

                <h1 className='project-subheader'><u>My Experience</u></h1>
                <p>This was my second year in a row participating in the HACC so I knew generally what to expect coming into it which alleviated a lot of the stress of the unknown 
                    in comparison to the first time I had participated in it. This was helpful as out of our group of 5 people, only me and Arslan had participated in the HACC 
                    before so we were able to give insight as to what the judges were looking for in the projects. As I mentioned before, we had team meetings about 4-5 days a week 
                    which allowed for us to be on the same page and on track to complete the project. We were also in constant communication with the challenge sponsor which allowed 
                    us to get constant feedback on our vision for the project and helped us know what to focus on.</p>
                <p>There were many challenges that we faced throughout this hackathon, both as a team and as an individual. As a team, it took us a few days to decide which 
                    challenge we wanted to tackle, which shortened our time of actually working on the project. Personally, this was my first time working exclusively on the back-end 
                    of a project which means this was also my first time working with the spring-boot framework. I was trying to learn the basic structure of an API while simultaneously 
                    trying to learn how to implement said structure using Java and the spring-boot framework. However, the fact that the HACC spans over multiple weeks allowed for me to 
                    take the first few days to learn the spring-boot framework after we decided which challenge we were going to attempt.</p>
                <p>As I mentioned before, from this project I learned about back-end development along with the spring-boot Java framework. I learned about best practices such as 
                    proper naming convention for endpoints and useful tools/software such as Postman which can be used to test API endpoints when building an API. Docker was another 
                    software I learned to use as our team decided to use Docker, rather than everyone having to setup postgresql locally, or hosting a database online. I also improved 
                    upon my time management skills as I had to balance work, school, and this project. While the outcome of the HACC was not one we wanted as we did not place in 
                    the top 3, the experience I gained from participating in the HACC is invaluable. If I were to participate in a future HACC again, I would try to stick to 
                    languages and frameworks I know well so that production is hopefully faster as I would not need to be learning the material as I am working on the project.</p>

                <ClioWalkthrough />

                <h1 className='project-subheader'><u>Link(s)</u></h1>
                <ul>
                    <li className='project-list'>A more detailed walkthrough which includes the admin walkthrough can be found at the Devpost project page <a target="_blank" rel="noopener noreferrer" href="https://devpost.com/software/project-clio?ref_content=user-portfolio&ref_feature=in_progress">here.</a></li> 
                    <li className='project-list'>The github repository can be found <a target="_blank" rel="noopener noreferrer"href="https://github.com/HACC2020/ByteCryb">here.</a></li>
                </ul>
                
                <hr/>

                <CarouselImages images={imgArr} />
                
            </Container>

        </div>
    )
}

export default ProjectClio;