import React from 'react';
import { Container } from 'react-bootstrap';
import { Icon } from 'semantic-ui-react';
import MyNavbar from '../components/MyNavbar';
import CarouselImages from '../components/CarouselImages';
import RadGrad from '../../static/images/radgrad.png';
import Careers from '../../static/images/careers.png';
import Home from '../../static/images/radgradhome.png';

function RadGradInternship(props) {

    const imgArr = [Home, RadGrad, Careers];

    function backPage(e) {
        e.preventDefault();
        props.history.push('/projects');
    }

    return (
        <div className='walkthrough'>

            <MyNavbar />
            
            <Container className="resume">
                <Icon name='arrow left' size='big' onClick={backPage}/>
                <h1 className='project-header'>RadGrad Summer Internship</h1>
                <hr/>

                <h1 className='project-subheader'><u>List of Essays Written During the Internship</u></h1>
                <p>
                  <ul>
                    <li><a href='/projects/radgrad/radgradreview'>A Critical Review of RadGrad</a></li>
                    <li><a href='/projects/radgrad/radgradoverview'>Why RadGrad?</a></li>
                    <li><a href='/projects/radgrad/radgradsandbox'>Building a Personal Sandbox</a></li>
                  </ul>
                </p>
                
                <hr/>

                <h1 className='project-subheader'><u>Overview</u></h1>
                <p>Over the summer of 2021 I was one of five students selected to participate in the NSF-Funded “Using Degree Experience Plans to
                    Improve Engagement, Retention, and Diversity of Undergraduates in Computer Science” Research
                    Experience for Undergraduates at the University of Hawaii at Manoa. The internship occurred over the course of 2 1/2 months in which I worked anywhere 
                    between 20-40 hours a week. Two weekly meetings occurred in which we discussed what we accomplished as well as what was expected next. The main goal of
                    the project over the summer was to make the second version of the web application called <a href='https://www.radgrad.org/' rel='noopener noreferrer' target='_blank'>RadGrad</a> ready
                    for public release into the UH Manoa ICS department by the Fall 2021 semester.</p> 

                <h1 className='project-subheader'><u>What is RadGrad?</u></h1>
                <p>RadGrad is a web application built using the MeteorJS framework which aims to increase engagement, retention, and diversity in the field of computer science by providing an alternative 
                    method to the undergraduate degree experience. RadGrad achieves this by elevating the status of extracurricular activites to be equal in importance to courses. Students are able to earn 
                    Innovation, Competency, and Experience points (aka. myICE) where students level up after reaching certain point milestones. Unlike a grade point average (GPA) which only reflect how 
                    well a student performs in the classroom, myICE is earned by taking courses as well as participating in extracurricular activities such as clubs and internships which can help make a resume 
                    stand out. RadGrad also provides a database of topic areas such as interests and career goals in addition to the database of courses, opportunities, and internships offered to students.
                </p>

                <h1 className='project-subheader'><u>My Experience</u></h1>
                <h2 className='project-subheader' id='subheader-subheader'>Onboarding</h2>
                <p>The summer began by familiarizing ourselves with RadGrad system through a set of onboarding tasks which started with performing an in-depth review of the current state of the website which is 
                    documented <a href='/projects/radgrad/radgradreview'>here.</a> After reviewing the system from a user's perspective, the next task was to review the research on RadGrad which gave us the 
                    overarching reasons for what RadGrad was created for, and why we should achieve them. This was an important step in the onboarding process as knowing what we are trying to achieve with the system 
                    helps influence decisions made when implementing or modifying features of the website. The final step of the onboarding process was to familiarize ourselves with the code through a set of sandbox exercises
                    which is documented <a href='/projects/radgrad/radgradsandbox'>here.</a>
                </p>

                <h2 className='project-subheader' id='subheader-subheader'>Developing</h2>
                <p>After completing the onboarding tasks, we were then ready to begin working on the RadGrad system. We utilized the GitHub project management board to organize what issues need to be addressed, what people are 
                    currently working on, and what was completed. When an issue was completed, we created a pull request so that professor Johnson and/or professor Moore could review the code that we had written before 
                    it was merged into master. I worked on a range of issues including writing test scripts to ensure certain pages or components loaded, creating UI components, and fixing bugs in the UI as well as when interacting 
                    with the database. In conjunction with working on RadGrad, some of us interns also worked on <a href='https://internaloha.github.io/documentation/' rel='noopener noreferrer' target='_blank'>InternAloha</a>, which
                    is an application that scrapes multiple websites for computer science related internships. As of right now InternAloha is a standalone application, however one of the goals is to integrate it as a plugin 
                    into RadGrad. I was one of the interns who helped to maintain the scrapers by fixing ones that broke as well as creating new ones. Code reviews were performed throughout the duration of the internship in which a few files were 
                    chosen to be reviewed to see if they follow certain guidelines or if it can be implemented in a better way. These code reviews helped to familiarize ourselves with the system even more and see what is 
                    considered "good" and "bad" code and understand why something should be implemented a certain way. 
                </p>

                <h2 className='project-subheader' id='subheader-subheader'>Pilot Study</h2>
                <p>The last few weeks of the internship focused on conducting pilot studies of the system and analyzing the results. 5 subjects participated in the pilot study with 2-3 interns conducting 
                    each study. A written protocol was followed so that the questions being asked were standardized across each interview. The pilot study sought to understand what features users felt were the 
                    most useful as well as features that may not be so useful. Bugs in the system were also noted. Getting fresh eyes on the website, especially from a non-developer perspective helped to identify 
                    a lot of user experience issues that were overlooked in development. The full technical report can be found <a href='https://csdl.ics.hawaii.edu/techreports/2021/21-01/21-01.pdf' rel='noopener noreferrer' target='_blank'>here</a>.
                </p>

                <h1 className='project-subheader'><u>What I Learned</u></h1>
                <p>With roughly 30,000 lines of code, RadGrad is by far the largest codebase I have worked with so far. All of the previous projects I have worked on, whether it be a personal project, class project, or for a hackathon, I was 
                    developing it from scratch. RadGrad however, is an already established system meaning that I had to learn how to integrate myself working on the system. Bugs that needed to be fixed meant that I had to analyze code written 
                    by another person which made me keep in mind when I wrote code that future developers would also need to be able to understand what I had written. Web scraping and writing test scripts were also skills that I had gained 
                    from this internship as before this I had no experience in either skill. I also learned how to conduct a pilot study and analyze the results, being able to distinguish what feedback is most relevant and what may not 
                    be so important.
                </p>

                <hr/>

                <CarouselImages images={imgArr} />
                
            </Container>

        </div>
    );
}

export default RadGradInternship;
